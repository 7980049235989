/*
@File: Wipdata ChatBot Dashboard

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.


/*
Default Style
============================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
/* font-family: 'Inter', sans-serif; */

/* Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

:root {
  /**
  @font family declaration
  */
  --font-poppins: 'Inter', sans-serif;
  --font-montserrat: 'Inter', sans-serif;
  /**
  @color declaration
  */
  --brand-color: #5956e9;
  --secondary-color: #423ef3;
  --paragraph-color: #7e7e7e;
  --paragraph-size: 17px;
  --orange-color: #fd346e;
  --heading-color: #292930;
  --gray-color: gray;
  --gray-color-2: #f8f8f8;
  --bg-color: #f2f4f8;
  --border-color: var(--bg-color);
  --font-bold: 500;
  --white-color: white;
  --wip-color: #f92c01;
  --wip-bg-img-color: radial-gradient(
    circle at top right,
    #fda40c 0%,
    #f92c01 100%
  );
  --secondary-bg: linear-gradient(115deg, rgb(42, 39, 218), rgb(0, 204, 255));
  --purpleColor: #c211a1;
  --shadow: 0 1px 1px rgb(0 0 0 / 10%);
  --hover-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  --header-height: 60px;
  --radius: 4px;
  --font-color: #7269ef;
  --green-color: #009688;
  --danger-color: rgb(221, 51, 51);
  --transition: all 0.3s ease-in-out;
  --sidebar-width: 5%;
  --sidebar-width-active: 18%;
  --sidebar-body-width: 95%;
  --sidebar-body-width-active: 82%;
  --green-color: #0566ff;
  --green-bg: #0566ff;
  --sidebar-icon-width: 68px;
  --blue-color: #3b8ad8;
}

.dark-theme {
  --bg: #151521;
  --bg-light: #1e1e2d;
  --p-color: #565674;
  --p-light-color: #d7d7d7;
  --h-color: #ffffff;
  --gray: gray;
  --model-bg: #000000cc;
  --popup-bg: #ffffffba;
  --loading-dot-bg: #3d3d53;
  --shadowColor: #1e1e2d;
}
.light-theme {
  --bg: #f8f7fa;
  --bg-light: #ffffff;
  --p-color: #565674;
  --p-light-color: #607d8b;
  --h-color: #565674;
  --model-bg: #ffffffba;
  --popup-bg: #000000d1;
  --loading-dot-bg: #3d3d53;
  --shadowColor: lightgray;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  color: var(--p-color);
  line-height: 26px;
  overflow-x: hidden;
  background: var(--bg);
  scroll-behavior: smooth;
  transition: var(--transition);
}

a {
  text-decoration: none;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-montserrat);
  color: var(--heading-color);
  margin-top: 0px;
  font-weight: var(--font-bold);
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: var(--font-poppins);
  font-size: 18px;
  font-weight: 400;
  color: var(--paragraph-color);
}

.z-index {
  position: relative;
  z-index: 3;
}
.common-button {
  padding: 8px 12px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: #009688;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
}
.common-button span {
  display: flex;
  align-items: center;
  grid-gap: 3px;
}
.common-button svg {
  color: white;
}
.p-relative {
  position: relative !important;
}
.loading-btn {
  width: 110px;
  height: 35px;
  background: #c5c5c5;
  cursor: not-allowed !important;
}
a,
.btn,
button,
input,
select,
textarea,
li,
img,
svg,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  /* font-family: var(--font-montserrat); */
}
input,
textarea,
select {
  padding: 10px;
  border: 1px solid var(--bg-light);
  border-radius: var(--radius);
  transition: var(--transition);
  outline: none;
  font-size: 17px;
  width: 100%;
  color: var(--paragraph-color);
  font-family: var(--font-montserrat);
  background: var(--bg);
}
input:focus,
textarea:focus,
select:focus {
  border: 1px solid var(--brand-color);
}
textarea {
  height: 150px;
  line-height: 26px;
  resize: vertical;
}
li {
  list-style: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

svg {
  font-size: 22px;
  color: var(--gray-color);
  cursor: pointer;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
  font-family: var(--font-poppins);
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
  font-family: var(--font-poppins);
}

input[type='color'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

::-moz-selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

::selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

*::-moz-placeholder {
  color: rgb(189, 189, 189);
  font-size: 15px;
  opacity: 1;
  font-weight: 200;
}

*::placeholder {
  color: rgb(173, 173, 173);
  font-size: 15px;
  opacity: 1;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 700;
}
.text-danger {
  color: var(--danger-color) !important;
  font-weight: 400;
  font-size: 15px;
}
.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}
/* Dashboard / Home screen Style
================================ */
section.main-section {
  width: 100%;
}
.dashboard-wrapper {
  width: 100%;
}
aside.sidebar {
  width: var(--sidebar-width);
  background: var(--bg-light);
  box-shadow: var(--shadow);
  height: 100%;
  position: fixed;
  z-index: 9;
  transition: var(--transition);
  box-shadow: 0px 0px 3px 0px lightgray;
  overflow: hidden;
}
aside.sidebar.active {
  width: var(--sidebar-width-active);
  visibility: visible;
  opacity: 1;
}
main.active {
  width: 100%;
  margin-left: var(--sidebar-icon-width);
  width: var(--sidebar-body-width);
}
aside.sidebar ul {
  display: flex;
  flex-direction: column;
}

aside.sidebar ul li {
}
.dropdown-icon {
  margin-right: 0px;
}
aside.sidebar ul li:nth-child(1) {
  border-bottom: 1px solid var(--gray-color-2);
  height: var(--header-height);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid lightgray;
}
aside.sidebar ul li a {
  font-size: 16px;
  color: var(--gray-color);
  font-weight: 400;
  padding: 10px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
}
.active-link {
  background: var(--bg);
}
.flex-label-icon {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: center;
}
.active-link::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 5px;
  background: #03a9f4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
aside.sidebar ul li svg {
  font-size: 25px;
}
main {
  margin-left: var(--sidebar-width-active);
  width: var(--sidebar-body-width-active);
  box-sizing: border-box;
  transition: var(--transition);
}
/* Header */
header {
  background: var(--bg-light);
  box-shadow: var(--shadow);
  height: var(--header-height);
  border-left: 1px solid var(--bg-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  transition: var(--transition);
  position: sticky;
  top: 0px;
  z-index: 999;
}
header.active {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  z-index: 999;
  transition: var(--transition);
}

.header-logo img {
  width: 100%;
  object-fit: contain;
}
.header-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.search-box {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-box input {
  height: 40px;
  border: 1px solid var(--bg);
  background: var(--bg);
  width: 400px;
  padding-left: 40px;
  border-radius: 5px;
}
.search-box svg {
  position: absolute;
  left: 10px;
}
.burger-menu-icon {
  font-size: 30px;
  cursor: pointer;
}
.header-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  position: relative;
}
.header-profile p {
  font-size: 15px;
  line-height: 22px;
  color: var(--p-light-color);
}
.header-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.header-profile svg {
  color: var(--gray-color);
}
.body-container {
  width: 100%;
}
.body-wrapper {
  /* padding: 30px; */
  width: 100%;
  transition: var(--transition);
}

.container {
  padding: 20px;
}

/* breadcrumbs-and-filter */
.breadcrumbs-and-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  display: none;
}
.blank {
  display: none;
}
.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 3px;
  color: var(--gray-color);
  font-size: 16px;
}
.date-filter {
  background: var(--bg-light);
  padding: 7px 20px;
  border-radius: 30px;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  color: var(--gray-color);
  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.date-filter h6 {
  color: lightslategrey;
  font-weight: 500;
}
.date-filter svg {
  font-size: 20px;
}
.header-logo-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;
  text-transform: capitalize;
}
.header-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: var(--hover-shadow);
}
.header-logo-content {
}
.role-badge {
  background: #00968917;
  color: #009686 !important;
  font-weight: 500;
  font-size: 11px !important;
  padding: 0px 5px;
  border-radius: 30px;
  text-align: center;
  width: max-content;
  line-height: 17px !important;
}

/* featured item */
.featured-status-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 20px;
  margin-top: 10px;
}
.featured-status-item {
  background: var(--bg-light);
  padding: 30px;
  border-radius: 8px;
  box-shadow: var(--hover-shadow);
  width: 100%;
  transition: 0.3s ease-in-out;
  min-height: 158px;
  cursor: pointer;
  border: 3px solid transparent;
}
.react-tabs {
  width: 100%;
}
.featured-status-item.react-tabs__tab--selected {
  border: 3px solid white;
}

.featured-status-item:hover {
  box-shadow: var(--shadow);
}

/* for desktop */
.featured-status-item:nth-child(1) {
  background: #2155cd;
}
.featured-status-item:nth-child(2) {
  background: #9c27b0;
}
.featured-status-item:nth-child(3) {
  background: #009688;
}

.featured-status-item:nth-child(4) {
  background: #683ab72c;
}
.featured-status-item:nth-child(5) {
  background: #683ab79c;
}
.featured-status-item:nth-child(6) {
  background: #673ab7 !important;
}

.featured-status-content h3,
.featured-status-content h1 {
  color: white;
}
.featured-bottom-content svg,
.featured-bottom-content span {
  color: white;
}

/* for special */

.featured-top-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.featured-status-content {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.featured-status-content h3 {
  font-size: 17px;
  font-weight: 500;
}
.featured-status-content h1 {
}
.featured-status-thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: var(--hover-shadow);
}
.featured-status-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.featured-bottom-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  color: var(--paragraph-color);
}

.percent-of-up,
.percent-of-down {
  background: green;
  border-radius: 30px;
  padding: 0px 10px;
  color: white;
}
.percent-of-down {
  background: var(--wip-color);
}

.up-icon {
  color: green;
}
.down-icon {
  color: var(--wip-color);
}

/* dropdown menu */
.header-profile-dropdown {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 200px;
  background: var(--bg-light);
  box-shadow: var(--hover-shadow);
  transition: var(--transition);
  border-radius: 3px;
  padding: 13px;
  visibility: hidden;
  opacity: 0;
}
.header-profile-dropdown.active {
  visibility: visible;
  opacity: 1;
  top: 52px;
  z-index: 999;
}
.header-profile-dropdown ul li {
  width: 100%;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #d3d3d326;
  color: var(--gray-color);
}
.header-profile-dropdown ul li:last-child {
  border-bottom: unset;
}
.header-profile-dropdown ul li:hover {
  background: var(--bg-light);
  border-radius: 5px;
}
.header-profile-dropdown ul li a {
  display: flex;
  justify-content: flex-start;
  grid-gap: 7px;
  align-items: center;
}
.header-profile-dropdown ul li a svg {
  font-size: 18px;
}

/* chart */
.chart-wrapper {
  width: 100%;
  grid-gap: 15px;

  display: flex;
  justify-content: space-between;
}
.chart-item {
  background: var(--bg-light);
  width: 50%;
  padding: 15px;
  box-shadow: var(--hover-shadow);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;
}
.chart-item svg {
  font-size: 12px;
}

/* chart header */
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.chart-header h3 {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray-color);
}
.chart-header-option svg {
  font-size: 25px;
}

.chart-svg-wrapper {
  width: 100%;
  height: 250px;
}
.mobile-sidebar-cross {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 33px;
  color: red;
  cursor: pointer;
  z-index: 9999;
}

/* Admin screen */
.admin-page-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
}

/* admin header */
.admin-header {
  background: var(--bg-light);
  padding: 20px 0px;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  background-color: #fff;
  background-color: var(--bs-sidebar-bg);
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  display: flex;
  height: 100vh;
  max-width: 75px;
  min-height: 570px;
  min-width: 75px;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.admin-header ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 20px;
}

.admin-header ul li {
}
.admin-header ul li img {
  width: 30px;
}
.admin-header ul li a {
}

.admin-header ul li svg {
  font-size: 23px;
  color: var(--gray-color);
}

.admin-header ul li svg:hover {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  color: #7269ef;
}
.admin-header svg.active {
  color: #7269ef;
}
/* admin chat body */
.chat-body-wrapper {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  width: 100%;
  height: 100%;
  transition: var(--transition);
  position: relative;
}
.chat-body-wrapper.active {
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 9999999999999;
  background: var(--bg-light);
}
.chat-body-sidebar {
  padding: 20px;
  /* border: 1px solid lightgray;
  border-top: unset;
  border-bottom: unset; */
  /* min-width: 400px;
  max-width: 400px; */
  background: var(--bg);
  height: 100%;
  margin-top: 1px;
  padding-bottom: unset;
  min-width: 370px;
}
.chatbox-sidebar-wrap {
  overflow: hidden;
}
.chat-body-search {
  margin-top: 10px;
  position: relative;
}
.chat-body-search input {
  padding: 12px;
  font-size: 15px;
  border-radius: 8px;
  border: unset;
  width: 100%;
  outline: none;
  background: var(--bg-light);
  font-family: poppins;
  padding-left: 35px;
}
.chat-body-search svg {
  position: absolute;
  left: 10px;
  top: 0px;
  color: #7a7f9a;
  height: 100%;
  font-size: 18px;
}
.featured-chats {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
}
.featured-chat-item {
  background: var(--bg-light);
  border-radius: 5px;
  padding: 4px 13px;
}
.featured-chat-item p {
  color: var(--gray-color);
  font-size: 12px;
  text-align: center;
  margin-top: 3px;
}
.featured-chat-thumb {
  width: 50px;
  height: 50px;
  position: relative;
}
.featured-chat-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.chatbox {
  width: 100%;
  /* display: flex;
  justify-content: space-between;
  flex-direction: column; */
}
.chat-message-body {
  width: 100%;
}
.chatbox-wrapper {
  width: 100%;
  position: relative;
}
.audio-hidden {
  display: none;
}

/* recent chat */
.chat-title {
  margin: 8px 0px;
  color: var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.add-new-group-icon {
  background: var(--bg-light);
  border-radius: 50%;
  font-size: 30px;
  padding: 5px;
  box-shadow: var(--hover-shadow);
}
.create-group-wrapper {
  margin-top: 10px;
}
.create-group-btn {
  background: var(--brand-color);
  padding: 10px 20px;
  border-radius: var(--radius);
  color: white;
  font-size: 16px;
  width: fit-content;
  margin-top: 7px;
}
.chat-title:first-child {
  margin-top: unset;
  margin-bottom: 0px;
}
.chat-title:last-child {
  margin-bottom: 10px !important;
}
.chat-title h2 {
  font-weight: 500;
  font-size: 15px;
  font-family: montserrat;
  color: var(--gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.chat-title-big h2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--gray);
}

.recent-chat {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  transition: all 0.3s ease 0s;
  max-height: 290px;
  scrollbar-width: thin;
  position: relative;
}
.pinned-recent-chat {
  height: 180px;
}
/* recent chat scrollbar width */
.recent-chat::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.recent-chat::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.recent-chat::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.recent-chat::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.recent-chat-wrap {
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}
.recent-chat-item {
  background: var(--bg-light);
  padding: 13px;
  border-radius: 3px;
  transition: background 0.3s ease-in;
  cursor: pointer;
  border: 2px solid transparent;
}
.recent-chat-item.active {
  border: 2px solid var(--green-color);
}
.recent-chat-item.active .recent-chatbox-content h2 {
  color: var(--blue-color) !important;
}
.recent-chat-item.active .recent-chatbox-user-chat svg,
.recent-chat-item.active .recent-chatbox-user-chat span,
.recent-chat-item.active .recent-chat-item-inner p,
.recent-chat-item.active .channel-right svg {
  color: var(--p-light-color) !important;
}
.recent-chat-item:hover {
  background: #cedeff;
}
.recent-chat-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: black;
}
.recent-chat-item-inner p {
  color: #7a7f9a;
  font-size: 12px;
}
.recent-chat-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
}
.recent-chatbox-content {
}
.recent-chatbox-content h2 {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray-color);
}
.recent-chatbox-content p {
  font-size: 13px;
  color: #7a7f9a;
}
.recent-chatbox-user-chat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.recent-chatbox-user-chat svg {
  color: #7a7f9a;
  font-size: 12px;
}
.recent-chatbox-user-chat span {
  color: #7a7f9a;
  font-size: 12px;
}
.typing-item-wrap {
  margin-top: 5px;
}
.typing-wrap {
  padding: 9px 13px !important;
}
.typing-wrap .typing {
  color: #000 !important;
}
.typing-in-chat {
  color: #7269ef !important;
  font-weight: 500;
}
.typing-in-chat .animate-typing .dot {
  background: #7269ef !important;
  margin-right: 3px;
}

/* call model */
.call-model-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(8, 8, 8, 0.507);
  transition: var(--transition);
  padding: 40px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  top: -50px;
}
.call-model-container.active {
  visibility: visible;
  opacity: 1;
  top: 0px;
}
.call-model-wrapper {
  background: var(--bg-light);
  border-radius: 8px;
  width: 450px;
  height: 300px;
  margin: auto;
  box-shadow: var(--brand-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
}
.call-user-avatar {
  width: 100px;
  height: 100px;
}
.call-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #d3d3d342;
}
.call-user-content {
  text-align: center;
}
.call-user-content h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--blackColor);
}
.call-user-content p {
  color: var(--paragraphColor);
  font-size: 14px;
}
.call-user-actions {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
}
.call-user-icon {
  background: #06d6a0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.call-user-icon svg {
  color: white;
  font-size: 23px;
}
.call-user-icon:first-child {
  background: red;
}
/* search modal */
.search-model {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 30px;
  transition: var(--transition);
  bottom: 0px;
  right: 150px;
  z-index: 999;
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
}
.search-model.active {
  visibility: visible;
  opacity: 1;
  top: 0px;
}
.search-model input {
  border: unset;
  background: var(--bg-light);
  border-radius: 8px;
  padding: 10px 15px;
  width: 350px;
  font-family: poppins;
  /* font-size: 16px; */
  padding-right: 35px;
  padding-left: 35px;
  outline: none;
}
.search-model svg {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--gray-color) !important;
  cursor: pointer;
  font-size: 13px;
}
svg.search-model-search-icon {
  position: absolute;
  left: 10px;
  top: 0px;
  color: #7a7f9a;
  height: 100%;
  font-size: 18px;
}

/* dropdown menu */
.dropdown-menu-wrapper {
  position: absolute;
  transition: var(--transition);
  top: 40px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
}
.dropdown-menu-wrapper.active {
  visibility: visible;
  opacity: 1;
  top: 60px;
}
.dropdown-menu-wrapper.active-chat-item {
  visibility: visible;
  opacity: 1;
  top: 30px;
}
.chatbot-dropdown.active {
  top: 75px;
}
svg.cross-chatbot-dropdown {
  width: 25px;
}
.dropdown-menu-wrapper ul {
  background: var(--bg-light);
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0px 1px 3px 0px lightgrey;
  width: 112px;
  height: 100%;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
.dropdown-menu-wrapper ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-color);
  grid-gap: 10px;
  margin-bottom: 5px;
  padding: 0px 7px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-size: 15px;
}
.dropdown-menu-wrapper ul li:hover {
  color: #7269ef;
}
.dropdown-menu-wrapper ul li svg {
  font-size: 18px;
}
.dropdown-menu-wrapper ul li:last-child {
  margin-bottom: unset;
}

/* admin dashboard */
.chatbox-recipient-avatar img {
  box-shadow: unset;
}
.chatbot-widget-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.chatbot-widget-recipient-details {
  color: white;
}
.chatbot-widget-recipient-details p {
  font-weight: 300;
  font-size: 13px;
  color: white;
  margin: unset;
  margin-bottom: 2px;
}
.chatbot-widget-recipient-details h3 {
  font-weight: 600;
  line-height: 10px;
  font-size: 13px;
  color: white;
}
.chatbot-widget-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  flex-direction: column;
  position: relative;
}
.chatbot-widget-header-right-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 3px;
}

.visitor-email {
  padding: unset !important;
  width: 100% !important;
  background: unset !important;
}

/* admin dashboard */
.chatbox-header-right-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
}
.chatbox-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbox-header-icon img {
  width: 14px;
  filter: brightness(0.5);
  cursor: pointer;
  transition: var(--transition);
}
.chatbox-header-icon svg {
  /* fill: var(--gray-color); */
  color: var(--gray-color);
  font-size: 20px;
  cursor: pointer;
  transition: var(--transition);
}

/* site cradit */
.chatbot-creator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.chatbot-creator p {
  font-size: 11px;
  color: white;
}
.chatbot-creator img {
  width: 18px;
  border-radius: 3px;
  object-fit: contain;
}
.chatbot-widget-options,
.chatbot-widget-minimize {
  fill: white;
  border-radius: 50%;
  font-size: 25px;
  position: relative;
  cursor: pointer;
}
.chatbot-widget-options svg,
.chatbot-widget-minimize svg {
  fill: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  transition: var(--transition);
}

.chatbot-widget-minimize svg {
}

.chatbot-widget-options svg:hover,
.chatbot-widget-minimize svg:hover {
  background: rgba(0, 36, 92, 0.16);
  border-radius: 50%;
}

/* chatbot body */
.chat-conversation-wrapper {
  padding: 0px 15px;
  width: 100%;
  overflow: hidden auto;
  background: var(--bg-light);
  transition: all 0.3s ease 0s;
  flex: 0 1 auto;
  scrollbar-width: thin;
  position: relative;
  /* max-height: 390px;
  min-height: 160px;
   */
  height: 70vh;
}
.chatbox-conversation {
  /* max-height: 75vh;
  min-height: 420px;
  height: 100%; */
  border-left: 2px solid var(--bg-light);
}
.chatbox-conversation-form {
  position: fixed;
  bottom: 0px;
  width: 618px;
  z-index: 999;
  background: var(--bg-light);
}
/* width */
.chat-conversation-wrapper::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.chat-conversation-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat-conversation-wrapper::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

/* Handle on hover */
.chat-conversation-wrapper::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.message-body {
  position: relative;
  margin-top: 10px;
  width: 100%;
  /* padding-bottom: 30px; */
  float: left;
}
.message-body li {
  margin: 0 0px 25px 0px;
  overflow-wrap: break-word;
  display: inline-block;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-bottom: 10px; */
  min-width: 70px;
}
.dodla-bot-msg-li {
  margin-bottom: unset;
}
.reset-style {
  margin: unset !important;
  padding: unset !important;
}

.chat-bubble-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: -webkit-fill-available;
}
.chat-bubble-wrap div {
  font-size: 13px;
  line-height: 20px;
  color: #000;
}
/* message agent */
.message-bubble-agent {
  color: #000;
  background: var(--bg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  width: 280px;
  width: fit-content;
  font-size: 13px !important;
  line-height: 20px;
  padding: 8px 14px;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  /* max-width: 85%; */
}
.message-bubble-agent a {
  color: var(--secondary-color);
  font-weight: 500;
}
/* message visitor */
.message-bubble-visitor {
  width: fit-content;
  max-width: 85%;
  float: right;
  background: var(--green-bg);
  color: white;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  font-size: 13px;
  line-height: 18px;
  margin: 10px 0px !important;
  padding: 12px 16px;
  margin-bottom: 10px;
}
.message-bubble-visitor span {
  /* text-transform: capitalize; */
}
.agent-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  box-shadow: 0px 0px 4px 0px lightgray;
}
.message-operator .messageTimestamp {
  height: 23px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  top: calc(100% + 4px);
  left: 12px;
}
.messageTimestamp,
.bot-date-response-badge {
  bottom: -20px;
  font-size: 9px;
  color: rgb(136, 148, 171);
  position: absolute;
  transition: all 0.2s ease 0s;
  white-space: nowrap;
  right: 0px;
}
.bot-date-response-badge {
  left: 0px;
}
/* message babble */
.animate-typing .dot {
  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
  background-color: #7269ef;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin-right: -1px;
  opacity: 0.6;
  width: 4px;
  margin-right: 5px;
}
.animate-typing .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.animate-typing .dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  margin-right: 0px;
}

@-webkit-keyframes wave {
  0%,
  60%,
  to {
    -webkit-transform: none;
    -webkit-transform: initial;
    transform: none;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes wave {
  0%,
  60%,
  to {
    -webkit-transform: none;
    -webkit-transform: initial;
    transform: none;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

/* chatbot footer */
.chatbot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 70px;
  grid-gap: 10px;
  margin-top: 2px;
  position: relative;
  border-top: 2px solid #f7f7f7;
}
.chatbot-footer form {
  width: 100%;
}
.relative {
  position: relative;
}
/* for admin dashboard */
.chatbot-footer-input-box {
  width: 100%;
  height: 40px;
  position: relative;
}
.chatbot-footer-input-box input {
  padding: 5px;
  border: 1px solid var(--bg-light);
  border-radius: 7px;
  height: 40px;
  width: 100%;
  padding-left: 20px;
  font-family: poppins;
  background: var(--bg-light);
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.chatbot-footer-input-box input:focus {
  outline: none;
  border: 1px solid #1189f0;
}
.chatbot-send-message-button {
  border: unset;
  background: var(--secondary-bg);
  color: white;
  border-radius: 7px;
  font-size: 17px;
  position: absolute;
  right: 5px;
  top: 3px;
  bottom: 5px;
  padding: 0px;
  width: 70px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  grid-gap: 7px;
  text-align: center;
  overflow: hidden;
}
.send-msg-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  grid-gap: 6px;
}
.chatbot-send-message-button svg {
  color: white;
  font-size: 16px;
}

/* day slot */
.message-day-slot {
  padding: unset !important;
  margin-top: 10px !important;
}
.message-day-slot-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 5px;
  flex-flow: row wrap;
}
.message-day-slot-item {
  background: var(--bg-light);
  border: 1px solid var(--secondary-color);
  padding: 4px 10px !important;
  font-size: 12px !important;
  color: var(--secondary-color);
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition) !important;
}
.message-day-slot-item.active {
  background: var(--secondary-color);
  color: white;
}
.message-day-slot-item:hover {
  background: var(--secondary-color);
  color: white;
  transform: translateY(-2px);
}

/* suggest button */
.message-auto-suggest-buttons {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  grid-gap: 5px;
  margin: 10px 0px;
  margin-bottom: 5px;
  flex-flow: wrap;
}
button.suggest-btn {
  border-radius: 4px;
  padding: 9px 13px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  border: 1px solid var(--green-color);
  align-items: center;
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 13px;
  background: var(--bg-light);
  color: var(--green-color);
  transition: all 0.3s ease-in-out;
  text-align: left;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.suggest-btn:disabled {
  background: #80808061;
  opacity: 0.5;
  cursor: not-allowed;
  color: black;
  border: 1px solid gray;
}

button.suggest-btn img {
}
button.suggest-btn span {
}
.clear-style-message {
  padding: unset !important;
  background: unset !important;
  box-shadow: unset;
}
button.suggest-btn:hover {
  background: var(--green-bg);
  color: white;
}
/* app progress */
.app-progress-section {
  padding: 70px 0px;
}
.app-progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 60px;
}
.app-progress-thumb {
  width: 80%;
  height: auto;
}
.app-progress-thumb img {
  width: 100%;
  height: 100%;
}
.app-progress-content {
  width: 100%;
}
.app-progress-content .sub-title {
  color: var(--blueDarkColor);
  font-weight: 500;
}
.app-progress-content h2 {
  font-family: montserrat;
  font-size: 40px;
  margin: 15px 0px;
}
.app-progress-content p {
  margin-bottom: 10px;
  font-size: 17px;
  color: var(--paragraphColor);
}
button.app-progress-button {
  background: var(--blueDarkColor);
  padding: 13px 23px;
  border-radius: 30px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  transition: var(--transition) 0s;
  box-shadow: rgb(0 0 0 / 31%) 0px 1px 5px 0px;
  font-family: 'Montserrat';
}
button.app-progress-button:hover {
  transform: translateY(-3px);
}
.partner-section {
  padding: 50px 0px;
}
.partner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}
.partner-item {
  width: 100%;
  height: auto;
  padding: 20px;
  cursor: pointer;
  transition: var(--transition);
}
.partner-item:hover,
.what-we-offer-item:hover {
  box-shadow: 0px 1px 20px 0px #d3d3d375;
  border-radius: 10px;
  transform: translateY(-3px);
}
.partner-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* product carousel */
.message-product-carousel {
  padding: unset !important;
}
.product-carousel-wrapper {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin: 10px 0px;
  width: 135%;
}
.chat-product-carousel-item {
  background: var(--bg-light);
  border-radius: 10px;
  overflow: hidden;
  width: 200px !important;
  height: fit-content;
  box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
  transition: var(--transition) !important;
  min-height: 165px;
}
.chat-product-carousel-item:hover {
  transform: translateY(-2px);
}
.chat-product-carousel-thumb {
  width: 100%;
  height: 120px;
  margin-bottom: 7px;
}
.chat-product-carousel-thumb img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
.chat-product-details {
  padding: 3px 5px;
}
.chat-product-details h2 {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
}
.chat-product-details p {
  font-size: 12px;
  line-height: 19px;
}
.chat-product-order-button {
  border: unset;
  background: var(--secondary-bg);
  color: white;
  margin-top: 5px;
  width: 100%;
  padding: 7px 10px;
  font-family: poppins;
}
.chatbot-footer-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.chatbot-footer-options svg {
  color: #1189f0;
  font-size: 20px;
}

/* carusaul */
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 95px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px;
  background: var(--bg-light);
  padding: 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset;
  color: var(--font-color);
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.1;
}
.featured-thumb {
  width: 100%;
  height: 100%;
  background-color: #1189f0;
}
.featured-thumb img {
  width: 100%;
  height: 100%;
}

/* chatbot */
.chatbot-widget {
  background: var(--bg-light);
  border-radius: 8px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
  height: 0px;
  width: 372px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: relative;
  z-index: -1;
  /* display: none; */
}
.chatbot-widget.active {
  visibility: visible;
  opacity: 1;
  height: 530px;
  z-index: 999;
  display: block;
  overflow: unset;
}

.chatbot-widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--secondary-bg);
  padding: 5px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/* for admin dashboard message header */
.chatbox-header {
  background: var(--bg-light);
  position: relative;
  transition: var(--transition);
  border: 1px solid #d3d3d354;
  border-bottom: 2px solid #d3d3d354;
  border-radius: unset;
  position: sticky;
  top: 60px;
  z-index: 99;
}
.chatbox-header p,
.chatbox-header h3 {
  color: var(--p-light-color);
  text-transform: capitalize;
}

.chatbot-widget-agent-precence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.chatbot-recipient-avatar {
  position: relative;
}
.online-status {
  position: absolute;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: var(--blue-color);
  border: 2px solid white;
  top: 0px;
  right: 0px;
}
.chatbot-recipient-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: contain;
  background: var(--bg-light);
  box-shadow: var(--brand-shadow);
  border: 2px solid white;
}

/* 404 not found */
.not-found-header {
  width: 100%;
}

.not-found-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%; */
}
.not-found-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 60%;
  margin: auto;
  margin-top: 40px;
}
.not-found-content {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.not-found-content h1 {
  font-size: 250px;
  line-height: 240px;
  color: var(--heading-color);
  text-shadow: 6px 6px 1px #ffffff;
}
.not-found-content h2 {
  font-family: var(--font-montserrat);
}
.not-found-content p {
}
.not-found-wrapper button {
  background: var(--brand-color);
  color: white;
  padding: 12px 25px;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  width: max-content;
  margin: auto;
  margin-top: 20px;
  font-size: 16px;
  box-shadow: var(--shadow);
  transition: var(--transition);
}
.not-found-wrapper button:hover {
  transform: translateY(-3px);
}
.not-found-wrapper button svg {
  color: white;
}

/* integration screen */
.integrate-container {
  padding: 20px;
}
.integrate-header {
  background: var(--bg-light);
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  border-left: 3px solid lightgray;
}
.integrate-header h3 {
  font-size: 18px;
  color: var(--gray-color);
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.integrate-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 15px;
}
.integrate-item {
  background: var(--bg-light);
  padding: 20px 20px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
}
/* .integrate-item.bot-demo-item::after {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  width: 200px;
  height: 200px;
  background: #00b47d;
  z-index: -1;
  -webkit-clip-path: polygon(94% 93%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(94% 93%, 100% 0%, 100% 100%, 0% 100%);
} */
.integrate-item.bot-demo-item h2 {
  margin-bottom: 5px;
}
.integrate-item.bot-demo-item p {
  line-height: 22px;
}
.integrate-item h2 {
  font-size: 19px;
  font-family: var(--font-montserrat);
  margin-bottom: 10px;
  color: var(--heading-color);
}
.integrate-item p {
  font-size: 15px;
  line-height: 25px;
}
.integrate-icon {
  width: 60px;
  height: auto;
}
.integrate-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.integrate-content {
}
.text-messenger {
  color: #446cff;
}
.text-instagram {
  color: #f52670;
}
.text-whatsapp {
  color: #2db842;
}
.text-telegram {
  color: #5c9ced;
}
.text-email {
  color: #00b2d0;
}
.text-twitter {
  color: #1d83ff;
}

/* Login and Register */
.register-container {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: var(--bg-color);
  width: 100%;
  z-index: 99;
  height: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-direction: column;
}
.password-model {
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}
.password-model.active {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}
.register-wrapper {
}
.register-wrapper form {
  width: 500px;
  height: 100%;
  margin: auto;
  background: var(--bg-light);
  padding: 30px;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
}
.form-row {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
}
.form-group {
  width: 100%;
  margin-bottom: 10px;
}
.form-group:last-child {
  margin-bottom: 0px !important;
}
.form-group label {
  color: var(--gray-color);
  font-size: 12px;
  font-weight: 300;
}
/* .form-group input, .form-group select{
  height: 50px;
} */

.form-group select {
}
.form-button {
  background-color: var(--font-color);
  padding: 10px 20px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  font-size: 17px;
  margin-top: 10px;
  transition: var(--transition);
}
.form-button:hover {
  transform: translateY(-3px);
}
.form-button.disabled {
  background: gray;
  cursor: not-allowed;
}
.already-have-account {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.already-have-account p {
  font-family: var(--font-montserrat);
  font-size: 13px;
}
.link {
  font-weight: 400;
  color: var(--brand-color);
}
.form-title {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--bg);
  font-family: var(--font-montserrat);
}
.form-title svg {
  color: var(--brand-color);
}
.form-title h2 {
  color: var(--font-color);
  font-weight: 600;
  font-size: 22px;
  /* margin-bottom: 10px; */
}
.form-title p {
  font-size: 16px;
  font-weight: 200;
}
.single-logo-wrapper {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 20px;
  top: 20px;
}
.single-logo {
  /* width: 60px;
  height: 60px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.single-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 7px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.096));
}
.logo-text {
}
.logo-text h2 {
  font-size: 20px;
  color: var(--wip-color);
  font-family: var(--font-poppins);
}

/* table */

/* product screen */
table {
  font-family: var(--font-poppins);
  border-collapse: collapse;
  width: 100%;
  /* background: var(--bg); */
}
tr {
  color: #2f4f4fc9;
}
.th-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* grid-gap: 5px; */
}
.data-table-body tr:first-child {
  font-weight: 600;
  text-transform: capitalize;
}
td,
th {
  /* border: 1px solid #dddddd4f; */
  border: 1px solid #6b6b6b4f;
  text-align: left;
  padding: 8px;
  color: var(--p-light-color);
  font-size: 14px;
}

tr:nth-child(even) {
  background-color: #dddddd1f;
}
.table-container {
  margin: 15px;
  position: relative;
}
.table-wrapper {
  width: 100%;
  background-color: var(--bg-light) !important;
  padding: 10px;
  overflow-x: auto;
  height: 78vh;
}

/* width */
.table-wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.table-wrapper::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #f1f1f1;
}

/* Handle */
.table-wrapper::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.178);
  border-radius: 30px;
}

/* Handle on hover */
.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-wrapper table {
  width: 100%;
  padding: 5px;
}
.data-table-body tr {
  font-weight: 500;
}
.data-table-body tr:first-child {
  font-weight: 700 !important;
}

.table-wrapper tbody tr td img {
  width: 110px;
  height: 65px;
  object-fit: cover;
  border-radius: 3px;
}
.action-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
}
.action-wrapper .edit-icon {
  color: var(--gray-color);
}
.action-wrapper .delete-icon {
  color: var(--danger-color);
}
.badge {
  color: var(--white-color);
  border-radius: 30px;
  font-size: 14px;
  padding: 3px 10px;
  text-transform: capitalize;
  box-shadow: var(--shadow);
}
.admin-badge {
  background: var(--brand-color);
}
.editor-badge {
  background: var(--green-color);
}
.table-name {
  font-weight: 500;
}
.user-badge {
  background: var(--paragraph-color);
}
.department-badge {
  color: green;
  background: rgba(0, 128, 0, 0.13);
  font-weight: 500;
}

/* table header */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-light);
  padding: 10px 8px;
  border-radius: var(--radius);
  margin-bottom: 10px;
}
.table-title h3 {
  font-size: 17px;
  color: grey;
  font-weight: 600;
}
.table-add-new {
  position: relative;
}
.table-add-new img {
  width: 20px;
}
.table-add-new .table-header-button {
  padding: 8px 9px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: var(--brand-color);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2px;
  min-height: 33px;
  min-width: 100px;
}
.table-add-new .table-header-button span {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
}

/* pagination */
.pagination-wrapper {
  background: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
}
.pagination {
  display: inline-block;
  display: flex;
  justify-content: flex-end;
}

.pagination a {
  color: var(--paragraph-color);
  float: left;
  padding: 0px 10px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: var(--brand-color);
  color: white;
  border: 1px solid var(--brand-color);
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination a:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination a:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/* create user model */
.create-user-wrapper {
  position: fixed;
  inset: 0;
  top: -100px;
  background: var(--popup-bg);
  display: flex;
  justify-content: center;
  transition: var(--transition);
  align-items: center;
  visibility: hidden;
  opacity: 0;
}
.create-user-wrapper.active {
  visibility: visible;
  opacity: 1;
  top: 0px;
  z-index: 999;
}
.create-user-form {
  width: 500px;
  height: max-content;
  margin: auto;
  background: var(--bg-light);
  padding: 30px;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
  position: relative;
}
.time-icon {
  color: var(--danger-color);
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

/* table filter */
.table-filter-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background: var(--bg-light);
  border-radius: 3px;
}
.table-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.table-filter .form-group {
  margin-bottom: unset;
}
.table-filter .form-group input,
.table-filter .form-group select {
  padding: 6px 10px;
  font-size: 14px;
  border: unset;
  /* background: #f2f4f880; */
  transition: var(--transition);
  border: 1px solid transparent;
  width: auto;
}
.table-filter .form-group input:focus,
.table-filter .form-group select:focus {
  border: 1px solid var(--brand-color);
}
.table-filter .form-group input {
  width: 300px;
}
.table-export {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.export-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1px;
  background: #673ab703;
  color: #673ab7;
  border-radius: 3px;
  padding: 3px 10px;
  box-shadow: var(--shadow);
  cursor: pointer;
  font-weight: 500;
}
.export-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  border: 2px solid #d3d3d352;
  padding: 8px;
  border-radius: 3px;
  background: #3f51b503;
  box-shadow: 0px 1px 2px 0px #d3d3d3ad;
  cursor: pointer;
  transition: var(--transition);
}
.export-wrapper .export-item:hover {
  border: 2px dotted #0080006e;
}
.export-button svg {
  color: #673ab7;
  font-size: 20px;
}
.export-item span {
  font-size: 16px;
  font-weight: 500;
  color: #008000;
}

/* import */
.import-item {
  border: 2px dotted var(--blue-color) 57;
  box-shadow: unset;
  cursor: pointer;
}
.import-item:hover {
  border: 2px solid dotted var(--blue-color) !important;
}
.import-item label {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  color: var(--blue-color);
  font-weight: 500;
  cursor: pointer;
}
.import-item label svg {
  color: var(--blue-color);
}
.import-item input {
}

.table-export svg {
  cursor: pointer;
}

.excel-text {
  color: green;
}
.pdf-text {
  color: var(--danger-color);
}
.word-text {
  color: rebeccapurple;
}
.reset-button {
  color: #8e8e8e;
  font-size: 28px;
  background: var(--bg);
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paid-badge {
  background: #00968917;
  color: #009686;
  border: 1px solid var(--green-color) 70;
  font-weight: 500;
}
.overdue-badge {
  background: rgb(221 51 51 / 5%);
  color: var(--danger-color);
  border: 1px solid rgb(221 51 51 / 35%);
  font-weight: 500;
}
.pending-badge {
  background: rgb(255 152 0 / 7%);
  color: #ff9800;
  border: 1px solid rgb(255 152 0 / 48%);
  font-weight: 500;
}

.price-badge {
  color: #3f51b5;
  background: #ffffff;
  font-weight: 600;
  border-radius: 30px;
}

/* Settings page */
.settings-container {
}
.settings-wrapper {
  background: var(--bg-light);
}
.settings-wrapper form {
  display: grid;
  grid-template-columns: 6fr 3fr;
  grid-gap: 30px;
  width: 100%;
}
.settings-left-wrapper {
  width: 100%;
}
.settings-right-card {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--bg-light);
  box-shadow: 0px 1px 4px -1px lightgrey;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
}
.settings-right-header {
  background: rgba(121, 218, 232, 0.15);
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  text-align: center;
  padding: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: var(--shadow);
  position: relative;
}
.settings-card-body {
  margin-top: 10px;
  padding: 10px;
}
.settings-right-header label {
}
/* Analytics screen */
.analytics-container {
  position: relative;
}
.analytics-wrapper {
  display: grid;
  grid-template-columns: 10fr 3fr;
  grid-gap: 10px;
}
.analytics-canvas {
}
.analytics-header {
  background-color: var(--bg-light);
  border-radius: 3px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
}
.analytics-header svg {
  color: gray;
}
.analytics-header-icons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 15px;
  align-items: center;
}
.analytics-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  width: 100%;
  background-color: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
  box-shadow: var(--shadow);
  height: 100%;
}
.analytics-sidebar {
  position: sticky;
  top: 0px;
}
.chat-init-not-found {
  height: 85vh;
}
.analytics-guide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  width: 70%;
  height: 100%;
  border-radius: 3px;
  text-align: center;
}
.analytics-guide h2 {
  color: lightgray;
  font-family: 'Montserrat';
}
.analytics-guide p {
  color: lightgray;
  font-size: 16px;
}
.analytics-guide img {
  opacity: 0.4;
}
.analytics-guide svg {
  font-size: 35px;
  opacity: 0.4;
}
.analytics-sidebar-item {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: 202px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
.analytics-fileds-wrapper {
}
.analytics-sidebar-fileds,
.analytics-sidebar-labels {
  background: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
}
.analytics-sidebar-labels {
  margin-top: 10px;
}

/* width */
.analytics-sidebar-item::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.analytics-sidebar-item::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #f1f1f1;
}

/* Handle */
.analytics-sidebar-item::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.178);
  border-radius: 30px;
}

/* Handle on hover */
.analytics-sidebar-item::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.field-item-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 3px;
  font-size: 17px;
  background: rgb(133 166 177 / 4%);
  padding: 4px;
  cursor: pointer;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
  background: #009688;
  height: 40px;
  border-radius: 5px;
}
.field-item-wrap.active {
  height: max-content;
  overflow-y: scroll;
}
.field-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 6px;
  font-size: 17px;
  background: #3f51b5;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
}
.x-axis-item {
  background: #9c27b0;
}
.field-item span {
  font-weight: 300;
  color: white;
  font-size: 16px;
}

.field-item.active {
  background: #add8e64a;
}
.field-item.active svg,
.field-item.active span {
  color: var(--brand-color);
  font-weight: 400;
}
.field-item svg {
  color: white !important;
}
.field-child-item {
  font-weight: 400;
  color: gray;
  font-size: 15px;
  background: #8080800d;
  padding: 3px 10px;
  cursor: move;
  transition: var(--transition);
  border-radius: 3px;
}
.field-child-item.active {
  background: black;
}
.field-child-item:hover {
  background: #0058501c;
  color: #009688;
}
/* label item */
.label-item {
  justify-content: space-between;
}
.label-content {
  display: inline-flex;
  grid-gap: 5px;
}
.label-action {
  display: inline-flex;
}

.analytics-sidebar-title {
  background-color: var(--bg-light);
  border-bottom: 2px solid #d3d3d321;
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
}
.analytics-sidebar-title h3 {
  font-weight: 600;
  font-size: 18px;
  color: darkgray;
}
.chart-text {
  /* background: #00968714; */
  padding: 4px;
  font-size: 30px;
  border-radius: 3px;
}
.chart-text.green {
  color: var(--green-color);
}
.chart-text.purple {
  color: #f44336;
}
.chart-text.orange {
  color: #9c27b0;
}
.chart-text.blue {
  color: #3f51b5;
}
.chart-text.skyblue {
  color: #2196f3;
}

.settings-wrapper .form-group input,
.settings-wrapper .form-group select,
.settings-wrapper .form-group textarea {
  color: var(--gray);
  border: 1px solid var(--bg-light);
}

.export-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  padding: 10px 0px;
}
.export-wrapper svg {
  font-size: 25px;
}

.error-text {
  color: var(--danger-color) !important;
  font-size: 13px;
}
.error-input {
  border: 1px solid var(--danger-color) !important;
}
.logout-btn {
  display: flex;
  grid-gap: 4px;
  background: #e91e6314;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: var(--transition);
  border: 1px solid transparent;
}
.logout-btn:hover {
  border: 1px solid #f080801f;
  transform: translateY(-1px);
}
.logout-btn,
.logout-btn svg {
  color: var(--wip-color) !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 12px;
}
.logout-btn svg {
  font-size: 18px;
}

.loading-wrapper {
  position: absolute;
  inset: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 5rem;
  border-left: 3px solid #4c4c4c;
  position: relative;
  transform: rotate(0deg);
  animation: spinning 1s linear infinite;
  z-index: 9999;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(-360deg);
  }
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}
.loader3 span {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #3f51b5;
  animation: loader3 1.5s linear infinite;
}
.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}
@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.error-box {
  background: orange;
  color: orangered;
  border-radius: 3px;
  text-align: center;
}
.table-data-not-found {
  background: #e91e6314;
  color: red;
  font-weight: 500;
  padding: 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  margin: 10px 0px;
}
.email-span {
  color: #009686;
}
.edit-disabled {
  cursor: not-allowed;
  color: #c1c1c1 !important;
}
.btn-disabled {
  cursor: not-allowed !important;
  background: #c1c1c1 !important;
  color: white !important;
}
.delete-disabled {
  cursor: not-allowed;
  color: #e91e1e6e !important;
}
.btn-disabled button {
  cursor: not-allowed;
  background: #c1c1c1 !important;
}
.export-disabled {
  cursor: not-allowed;
  color: #c1c1c1 !important;
}
.export-disabled span,
.export-disabled svg {
  cursor: not-allowed;
  color: #c1c1c1 !important;
}
.btn-import {
  padding: 11px 20px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: var(--blue-color);
  font-size: 15px;
  height: 100%;
  font-weight: 500;
}
.group-right-buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 5px;
  align-items: center;
}

.clear-data {
}
.clear-data button {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  background: #f44336;
}
.clear-data button svg {
  font-size: 16px;
  color: white;
}
.import-data-body-btn {
  padding: 10px 15px !important;
  background: var(--blue-color) !important;
  font-size: 15px !important;
  margin-top: 10px;
  transition: var(--transition);
}
.import-data-body-btn:hover {
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
}
.mini-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-light);
  padding: 10px 0px;
  border-radius: var(--radius);
  margin-bottom: 10px;
}
.filename-span {
  font-size: 16px;
  color: #607d8b;
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 5px;
}

/* sheet tab */
.sheets {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}

.sheet {
  background: #00968812;
  border-radius: 5px;
  font-size: 14px;
  padding: 7px 10px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--green-color);
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 10px;
  text-transform: capitalize;
  box-shadow: 0px 0px 0px 0px transparent;
  cursor: pointer;
  transition: var(--transition);
  width: max-content;
}
.sheet.active {
  color: var(--white-color);
  background: var(--green-color);
  box-shadow: var(--shadow);
}
.save-data-btn {
  background: var(--green-color) !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}

.save-data-btn svg {
  color: white !important;
  font-size: 16px;
}
.table-add-new button svg {
  font-size: 20px;
  color: white !important;
}
.saved-btn {
  background: #a0a0a0 !important;
}

.not-found-data {
  /* position: fixed; */
  inset: 0;
  z-index: 9999;
}
.not-found-data svg {
  font-size: 40px;
  color: lightgray !important;
}
.not-found-data .analytics-body {
  margin-top: unset;
}

/* accoridian */
.accordion-wrapper {
  background: #a6c3f70f;
  border-radius: 3px;
  position: relative;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #009688;
  position: relative;
  color: #ffffff;
  transition: var(--transition);
}
.accordion-title h4 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat';
  align-items: center;
  color: var(--accent-color);
  text-transform: capitalize;
}
.accordion-list {
  margin-top: 10px;
  margin-left: 10px;
}
.accordion-title::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--loading-dot-bg);
}
/* .accordion-title:hover, .accordion-title.open {
  color: var(--brandColor);
} */
.accordion-title.open {
  position: absolute;
  width: 100%;
}
.accordion-title.open::after {
  content: '';
  border-top: 0;
  border-bottom: 5px solid;
}
.accordion-title.open .accordion-item {
  overflow: unset !important;
}
.accordion-content {
  background: var(--bg-light);
  padding: 2px 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  margin-top: 35px;
}
.analytics-table {
  background: var(--bg-light);
  margin-top: 10px;
  border-radius: 5px;
  height: 80vh;
  overflow-y: auto;
  padding: 20px;
}

/* recent chat scrollbar width */
.analytics-table::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.analytics-table::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.analytics-table::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.analytics-table::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

/* dnd */
.y-axis-drag-canvas {
  background: var(--bg-light);
  border: 2px dotted #8080804f;
  padding: 5px;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
}
.board {
  border: 2px dotted gray;
  background: var(--bg-light);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  min-height: 200px;
}
.board.active {
  border: 2px dotted green;
}

.main_content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 30px;
  margin: 20px;
}

/* analytics chart wrapper */
.analytics-chart-mask {
  background: var(--bg-light);
  padding: 15px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  margin-top: 10px;
  max-width: 100%;
  width: 100%;
  height: 470px;
}

.analytics-chart-mask svg {
  font-size: 13px;
}

/* React tabs */
.react-tabs__tab {
  border: unset !important;
}
.react-tabs__tab:focus {
  border: unset !important;
}
.react-tabs__tab.react-tabs__tab--selected:focus {
  border: unset !important;
}
.react-tabs__tab.react-tabs__tab--selected {
  border-radius: 3px;
  border: unset !important;
}
.react-tabs__tab.react-tabs__tab--selected svg {
  background: #00968714;
}

.text-chart-analytics-tbody tr:first-child {
  font-weight: normal !important;
}
.recharts-text.yAxis-value {
  fill: #3f51b5;
  font-weight: 500;
  position: relative;
}
/* .recharts-text.yAxis-value tspan::before {
  content: '';

  bottom: 0px;
  position: absolute;
} */

.new-shape {
  background: red;
  height: 20px;
  width: 20px;
}
.recharts-text.xAxis-value {
  fill: #9c27b0;
  font-weight: 300;
  text-transform: capitalize;
}
.pie-chart-analytics-wrapper {
  width: 100%;
  height: 100%;
}
.pie-chart-analytics-wrapper canvas {
  width: auto !important;
  height: 100% !important;
  margin: auto;
}

/* user profile screen
====================== */
.user-profile-section {
  background: var(--bg-light);
  border-radius: var(--radius);
  margin: 15px;
  position: relative;
}
.user-profile-header-wrapper {
  background: var(--bg);
  padding: 30px;
  border-radius: var(--radius);
  position: relative;
}
.user-profile-header-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 30px;
}
.user-profile-header-thumb {
  width: 130px;
  height: 130px;
  position: relative;
  box-shadow: var(--shadow);
  border-radius: 50%;
  position: relative;
}
.user-profile-header-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.user-update-profile {
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--bg-light);
  box-shadow: var(--hover-shadow);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.user-update-icon {
}
.user-profile-content {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.user-profile-content .settings-title {
  background: var(--bg-light);
  border-radius: 30px;
  padding: 3px 10px;
  box-shadow: var(--shadow);
  font-size: 20px;
  color: var(--h-color);
}
.settings-profie-content {
  margin-top: 10px;
}
.user-profile-content h2 {
  font-size: 26px;
  color: var(--h-color);
}
.user-profile-role-and-department {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
}
.user-profile-role-and-department span {
  padding: 2px 14px;
}
.user-profile-body {
  margin-top: 30px;
}
.user-profile-body .form-field-wrapper {
  max-width: 600px;
}
.user-title-name {
  color: var(--brand-color);
}
.create-user-profile {
  width: 70px;
  height: 70px;
}
.create-user-profile .create-user-profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.create-user-profile-update-icon-wrapper {
  height: 30px;
  width: 30px;
  right: -8px !important;
}
.create-user-profile-update-icon-wrapper svg {
}
.mode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mode svg {
  height: 100%;
}
.no-number-badge {
  background: #ffe50033;
  padding: 2px 5px;
  border-radius: 30px;
  font-size: 13px;
}
.user-join-date p {
  font-size: 12px;
  background: var(--bg-light);
  font-weight: 500;
  width: fit-content;
  padding: 1px 10px;
  border-radius: 30px;
  box-shadow: var(--hover-shadow);
}
.user-join-date {
  margin-top: -5px;
}
.user-update-date {
  position: absolute;
  bottom: -11px;
  right: 30px;
}

/* .password-error {
  border-bottom: 3px solid red;
} */
.settings-thumb {
  margin: auto;
  width: fit-content;
  border-radius: unset;
  height: 100px;
  box-shadow: unset;
  background: white;
  padding: 8px;
  border-radius: 3px;
  box-shadow: var(--shadow);
}
.settings-thumb img {
  border-radius: unset;
  object-fit: contain;
  border-radius: 3px;
}
.settings-upload-icon {
  bottom: -11px;
  right: -11px;
}
.header-site-title {
  font-size: 18px;
}
.header-sidebar-logo {
  max-width: 95%;
  height: 40px;
}
.header-sidebar-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pie-chart-column-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}
.pie-chart-style {
}

/* loading */
.mini-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fffffff7;
  width: 100%;
  height: 100%;
}
.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.disabled {
  cursor: not-allowed !important;
}
.color-green,
.text-green {
  color: green;
  font-weight: 300;
}
.active-user-row {
  background: #0080001c !important;
  border-left: 3px solid var(--brand-color);
}

/* new loading */
/* <div className="xui-loader xui-loader-small xui-button--loader xui-loader-retain-layout" role="progressbar">
<div className="xui-loader--dot"></div>
<div className="xui-loader--dot"></div>
<div className="xui-loader--dot"></div>
</div> */
.xui-loader {
  align-items: center;
  color: rgba(0, 10, 30, 0.65);
  display: flex;
  justify-content: center;
}
.xui-loader-retain-layout {
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background: var(--model-bg);
}
.xui-loader--dot {
  animation: xui-loader-animation 1.3s infinite
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-fill-mode: both;
  background-color: var(--loading-dot-bg);
  border-radius: 50%;
  box-shadow: 0 0 1px var(--loading-dot-bg);
  display: inline-block;
  transform-style: preserve-3d;
  vertical-align: middle;
}
.xui-loader--dot:nth-child(1) {
  animation-delay: -0.32s;
}
.xui-loader--dot:nth-child(2) {
  animation-delay: -0.16s;
}
.xui-loader--dot:nth-child(3) {
  animation-delay: 0s;
}
.xui-loader-medium .xui-loader--dot {
  height: 14px;
  margin-right: 3px;
  width: 14px;
}
.xui-loader-small .xui-loader--dot {
  height: 12px;
  margin-right: 2px;
  width: 12px;
}
.xui-loader-xsmall .xui-loader--dot {
  height: 10px;
  margin-right: 2px;
  width: 10px;
}
@keyframes xui-loader-animation {
  0%,
  100%,
  80% {
    transform: scale(0.5) rotate(0);
  }
  40% {
    transform: scale(1) rotate(0.02deg);
  }
}

.xero-table-wrapper {
  background: var(--bg-light);
  position: relative;
  height: 100%;
  /* width: 100%; */
  margin-top: 10px;
  overflow-x: auto;
  height: 70vh;
}

.xero-table-wrapper table {
  /* margin: 10px; */
  background: var(--bg-light) !important;
  border-radius: 3px;
}
.xero-error {
  font-size: 20px;
  background: #ff45001c;
  color: #f44336;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  /* margin: 10px 0px; */
  text-align: center;
}
.table-header-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  position: relative;
}
.table-header-button-wrapper .table-header-button {
  text-align: center;
  transition: var(--transition);
}
.table-header-button-wrapper .table-header-button:nth-child(2) {
  background: #0088cc;
}

.table-header-button-wrapper .table-header-button:nth-child(2):hover {
  background: var(--green-color);
}

.notify-container {
  background: white;
  padding: 20px 25px;
  border-radius: 5px;
  /* height: 400px; */
  /* width: 300px; */
  position: absolute;
  top: 46px;
  right: -8px;
  z-index: 99;
  box-shadow: var(--hover-shadow);
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  transition: all 0.3s ease-in-out;
}
.choose-table-header {
}
.choose-amount-filed input {
  width: 100px;
  text-align: center;
}
.choose-option {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
}
.choose-option .choose-button {
  width: 100%;
  border: 2px dotted #c3c3c3;
  background: #f2f4f81a;
  color: #0088cc;
  box-shadow: unset;
  font-weight: 500;
  transition: var(--transition);
  min-width: min-content;
}
.choose-option .choose-button svg {
  color: #0088cc !important;
}

.react-tabs__tab--selected .choose-button {
  background: #607d8b;
  color: white;
  border: 2px solid transparent;
}
.react-tabs__tab--selected .choose-button svg {
  color: #fff !important;
}

.choose-table-header select {
  font-size: 14px;
}

.choose-item button {
  padding: 8px 9px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: var(--brand-color);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2px;
  min-height: 33px;
  min-width: 100px;
}
.choose-send-button button {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  background: #009688;
  margin-top: 10px;
  background-image: linear-gradient(
    270deg,
    rgba(100, 181, 239, 0) 48.44%,
    #00a797 75.52%,
    rgba(100, 181, 239, 0) 100%
  );
  background-repeat: no-repeat;
  animation: bg-move linear 5s infinite;
}
button.disabled {
  background: darkgray;
}
.api-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.api-item {
  background: #80808024;
  color: #607d8b;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 400;
}
.api-item.react-tabs__tab--selected {
  background: var(--blue-color);
  color: white;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
}
.api-item.react-tabs__tab--selected:focus {
  border: unset !important;
  outline: none !important;
}
.table-title {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
}
.aged-title {
  inset: 0;
}

/* analytics filter 
=================== */
.analytics-filter-container {
  position: fixed;
  right: -100px;
  background: white;
  height: 100%;
  top: 0px;
  width: 300px;
  box-shadow: 0px 0px 7px 0px lightgray;
  padding: 20px;
  transition: var(--transition);
  visibility: hidden;
  opacity: 0;
}
.analytics-filter-container.active {
  right: 0px;
  opacity: 1;
  visibility: visible;
  z-index: 999;
}
.dropdown-value-filter {
  width: 100%;
  height: inherit;
}
.dropdown-value-filter form {
  height: 100%;
}
.value-filter-container {
  box-shadow: unset;
  border-radius: unset;
  position: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 78vh;
  overflow-y: scroll;
  padding-right: 10px;
  grid-gap: 10px;
}

/* recent chat scrollbar width */
.value-filter-container::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.value-filter-container::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.value-filter-container::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.value-filter-container::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.filter-title {
  color: var(--brand-color);
  font-size: 20px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #fafafa;
  padding-bottom: 10px;
}
.filter-times {
  color: var(--orange-color);
  font-size: 30px;
}
.analytics-choose-amount-filed {
  width: 100%;
}
.analytics-filter-btn {
  margin-top: 12px;
}

@-webkit-keyframes bg-move {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@keyframes bg-move {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.filter-sidebar-title-and-reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-sidebar-title-and-reset svg {
  color: var(--orange-color);
  font-size: 20px;
}
.filter-sidebar-title-and-reset h5 {
  text-transform: capitalize;
  font-size: 15px;
  line-height: 0px;
  font-weight: 600;
  color: #009688;
}
.item-action-btn {
  display: flex;
}
.label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}
.label-wrapper li {
  display: inline-block;
}

.Custom-Tooltip {
  background: green;
  padding: 10px;
  color: white;
  border: unset !important;
  outline: none;
}
.tooltip-label {
  color: wheat;
}
.chat-send-time {
  font-size: 9px;
  margin-top: 16px;
  position: absolute;
  bottom: -4px;
  right: 17px;
  width: max-content;
}
.chat-message-wrapper {
  position: relative;
  width: fit-content;
}
.channel-right {
  position: relative;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  grid-gap: 10px;
}
.channel-right svg {
  font-size: 16px !important;
}
.create-group-logo {
  width: 80px;
  height: 80px;
}
.required {
  color: red !important;
}
.not-shadow {
  box-shadow: unset !important;
}
.chat-cov-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.integrate-btn {
  border: 1px solid var(--green-color);
  padding: 10px 20px;
  border-radius: 30px;
  margin-top: 10px;
  color: var(--green-color);
  font-size: 14px;
  box-shadow: var(--shadow);
  transition: all 0.3s ease-in-out;
}
.integrate-btn:hover {
  background: var(--green-color);
  color: white;
  transform: translateY(-3px);
}
.integrate-status {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #0096880d;
  border-radius: 30px;
  padding: 1px 11px;
  color: #009688;
  font-weight: 500;
  font-size: 12px;
}
.not-connected {
  background: rgba(178, 34, 34, 0.068);
  color: firebrick;
}

/* notification screen */

.notification-tbody {
}
.notification-tbody tr {
  border-bottom: 1px solid #9e9e9e26;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out;
}
.hover-icons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.hover-icons svg {
  font-size: 20px;
  margin-left: 5px;
}

.hover-icons {
  /* visibility: hidden;
  opacity: 0; */
  display: none;
}
.hover-date-item {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  align-items: center;
  margin-right: 8px;
}
.date-item input {
  font-size: 15px;
}
.notification-tbody tr:hover {
  background: #f2f4f8;
}
.notification-tbody tr:hover .hover-icons {
  /* visibility: visible;
  opacity: 1; */
  display: block !important;
  text-align: end;
  margin-top: 5px;
}
.notification-tbody tr:hover .date-item {
  display: none;
}
/* 
.notification-tbody tr:hover .hover-date-item {
  display: none !important;
} */
.notification-tbody td {
  border: unset !important;
}
.notification-tbody tr:first-child {
  border-top: unset !important;
}
.notification-tbody tr:last-child {
  border-bottom: unset !important;
}
.noti-title {
  font-weight: 600;
  font-size: 15px;
  color: #2c404a;
}
.noti-title p {
  width: max-content;
}
.flex-noti-title {
  display: flex;
  justify-content: flex-start;
  grid-gap: 7px;
  align-items: center;
}
.reset-noti {
  background: unset;
  width: unset;
  height: unset;
  font-size: 22px;
}
.noti-filter input,
.noti-filter select {
  background: unset;
  border: 1px solid #d3d3d363 !important;
  height: 40px;
}
.details-sidebar-container {
  position: absolute;
  inset: 0;
  background: #0000006e;
  z-index: 999;
  width: 100%;
  height: auto;
  bottom: 0px;
  visibility: hidden;
  right: -400px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.details-sidebar-container.active {
  visibility: visible;
  opacity: 1;
  right: 0px;
}
.details-sidebar {
  position: absolute;
  top: 0px;
  right: -100px;
  bottom: 0px;
  height: 100%;
  width: 600px;
  z-index: 9999;
  background: white;
  box-shadow: 0px 0px 4px -1px #607d8b;
  transition: all 0.3s ease-in-out;
  padding: 40px;
}
.details-sidebar.active {
  visibility: visible;
  opacity: 1;
  right: 0px;
}
.details-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid #607d8b0f;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.left-sidebar-details {
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  align-items: center;
}
.left-sidebar-details h2 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  color: var(--green-color);
}
.left-sidebar-details h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4c4c4c;
}
.right-sidebar-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.mb-unset {
  margin-bottom: unset;
}
.mb-unset select {
  font-size: 14px;
}
.action-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
}
.action-icons svg {
  font-size: 20px;
}
.close-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  background: #e91e630f;
  border-radius: 50%;
}
.close-icon svg {
  font-size: 31px;
  padding: 4px;
  color: red;
}
.left-sidebar-details-thumb {
  width: 40px;
  height: auto;
}
.left-sidebar-details-thumb img {
  width: 100%;
  height: 100%;
}
.sidebar-article h2 {
}
.sidebar-article h3 {
}

/* body */
.details-sidebar-body {
  max-height: 415px;
  overflow-y: auto;
  min-height: fit-content;
  padding-right: 10px;
}

/* width */
.details-sidebar-body::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.details-sidebar-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
.details-sidebar-body::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.39);
  border-radius: 30px;
}

/* Handle on hover */
.details-sidebar-body::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.26);
}
.table-social-icon {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain !important;
}
.sidebar-agent {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin-top: 20px;
}
.agent-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.agent-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.agent-details {
}
.agent-details h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 14px;
  text-transform: capitalize;
}
.agent-details p {
  color: gray;
  font-size: 13px;
}
.details-sidebar-wrap {
  margin-top: 5px;
}
.details-sidebar-wrap h2 {
  font-size: 19px;
  margin-bottom: 10px;
  font-weight: 500;
}
.details-sidebar-wrap p {
  font-size: 15px;
  text-transform: capitalize;
}
.sub-menu-wrapper {
  margin-left: 20px;
}
.sub-menu-wrapper ul {
  display: unset !important;
}
.sub-menu-wrapper ul li {
  border: unset !important;
  height: unset !important;
}
.dropdown-menu-item {
  height: 43px;
  overflow: hidden;
}

.bot-demo-launch-btn {
  border: 1px solid #00968821;
  color: var(--green-color);
  background: #0096880f;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.editor-not-allow {
  background: white;
  border-radius: 3px;
  box-shadow: var(--shadow);
  margin: 20px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
}
.editor-not-allow h1 {
  color: var(--gray-color);
}
.editor-na-alart {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
}
.editor-na-alart span {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: indianred;
}
.editor-na-alart svg {
  font-size: 60px;
  color: red;
}
.appointment-form {
  width: 90%;
  box-shadow: 0px 0px 4px 0px #d3d3d399;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.appointment-btn {
  background: var(--green-bg);
  padding: 7px 15px;
  border-radius: 30px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  /* width: 80px; */
}
.form-group.appo-group {
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  width: 100%;
}
.space-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: unset !important;
  margin-top: 10px;
}
.form-group.appo-group input,
.form-group.appo-group select {
  font-size: 14px;
}
.form-group.appo-group:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 7px;
}
.form-group.appo-group label {
  width: 30%;
}
.form-group.appo-group input,
.form-group.appo-group select {
  width: 70%;
}
.factory-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
}
.factory-btns svg {
  background: #0096880f;
  border-radius: 30px;
  font-size: 40px;
  padding: 8px;
}
.appo-title {
  margin-bottom: 10px;
}
.appo-title h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #145da0;
  width: max-content;
}
.appo-title p {
  font-size: 13px;
  line-height: 18px;
}
.error-notifi {
  color: #e62f22;
  font-weight: 500;
}

.curosul-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  margin-top: 10px;
  width: 100%;
}
.slider-item {
  box-shadow: var(--hover-shadow);
  border-radius: 5px;
}
.slider-item li {
  border-bottom: 1px solid #d3d3d366;
  padding: 3px 10px;
  margin-bottom: unset !important;
  font-size: 11px;
  line-height: 16px;
}
.slider-item li:last-child {
  border-bottom: unset;
}
.slider-item img {
  width: 50px;
  padding: 3px 10px;
}
.renew-btn {
  color: var(--brand-color);
  font-weight: 500;
}

.featured-list-bot-thumb {
  width: 70px;
  height: 60px;
}
.featured-list-bot-thumb img {
  border-radius: unset !important;
  object-fit: contain;
}

/* Chatbot */
.chatbot-section {
  position: relative;
}
.chatbot-widget-wrapper {
  position: fixed;
  right: 30px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 15px;
  z-index: 99999;
  /* visibility: hidden;
  opacity: 0; */
}

/* chatbot icon */

.chatbot-times-icon {
  box-shadow: var(--brand-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  overflow: hidden;
  z-index: 9999;
  position: absolute;
  left: -35px;
  top: 0px;
}
.chatbot-times-icon:hover .chatbot-times-icon svg {
  color: green;
}
.chatbot-times-icon svg {
}
.chatbot-times-icon img {
}
.chatbot-message-body {
  padding: 5px 0px !important;
}
.factory-error-box {
  background: #f2f4f8;
  padding: 10px;
  border-radius: 16px;
}
.factory-error-box li {
  margin: unset !important;
  line-height: 22px;
}
.chatlogs-send-btn {
  width: 80px !important;
  height: 33px;
}
.emoji-wrap {
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.emoji-wrap.active {
  position: absolute;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}
.sub-group-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  margin: 10px 0px;
  flex-wrap: wrap;
}
.api-sub-item {
  background: var(--bg-light);
  padding: 0px 7px;
  border-radius: 4px;
  color: var(--blue-color);
  border: 1px solid #196d6342;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.api-sub-item-active {
  background: var(--blue-color);
  padding: 0px 7px;
  border-radius: 4px;
  color: white;
  border: 1px solid #196d6342;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.api-sub-item:hover {
  background: var(--blue-color);
  color: white;
}

/* bot preview */
.chatbot-mini-preview-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--secondary-bg);
  border-top-left-radius: 5px;
  border-top-right-radius: 20px;
  padding: 6px 20px;
  grid-gap: 35px;
  cursor: pointer;
}
.chatbot-mini-preview-wrap.active {
  display: none;
}
.chatbot-mini-preview-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.bot-preview-img {
  width: 19px;
}
.send-us-message {
  font-weight: 400;
  color: white;
}
.chatbot-mini-preview-right {
}
.chatbot-mini-preview-right h5 {
  font-size: 12px;
  color: white;
  font-weight: 500;
}

/* abdus_shakur */
.report-block {
  margin-top: 10px;
  /* background-color: white; */
  padding: 5px 5px;
  border-radius: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.report-block h5 {
  font-weight: 600;
  margin: 10px 0px;
  font-size: 15px;
  border-bottom: 1px solid #607d8b;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 3px;
  margin-bottom: 10px;
}
.report-block h6 {
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 14px;
}
.refill-item {
  height: 100px;
  width: 100px;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
}
.refill-img {
  border-bottom: 1px solid #0393d6;
  border-top: 1px solid rgb(3, 147, 214);
  display: flex;
  flex-direction: column;
}
.refill-img img {
  width: 60% !important;
  margin: 0 auto;
}
.refill-item h6 {
  padding: 6px 0 0 0;
  text-align: center;
  font-size: 14px;
}
.refill-item button {
  background-color: rgb(3, 102, 148);
  color: white;
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 50px;
  margin: 8px auto;
}
.delete-chat-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}

.delete-chat-icon span {
  font-size: 13px;
}
.refresh-icon {
  background: unset !important;
}
.refresh-icon svg {
  color: #fff;
  fill: unset !important;
}

.welcome-the-bot {
  position: absolute;
  background: green;
  padding: 20px;
  border-radius: 20px;
  bottom: 0px;
  left: 0px;
}
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}
.welcome-the-bot p {
}
.welcome-the-bot button {
}
.extarnal-link {
  border: 1px solid var(--green-bg);
  color: var(--green-bg);
  border-radius: 4px;
  padding: 8px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 4px;
  font-weight: 600;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.extarnal-link:hover {
  color: var(--green-color) !important;
}
.extarnal-link svg {
  color: var(--brand-color);
  font-size: 15px;
}
.react-datepicker__input-container {
  width: max-content !important;
}
.react-datepicker__input-container input,
.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
  font-size: 13px !important;
  color: #000;
}
/* react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--select */
.react-datepicker {
  font-family: var(--font-montserrat);
  font-size: 13px;
}
.booking-date-field {
}
.booking-date-wrapper {
  margin-top: 10px;
}
.bot-form {
  margin-bottom: unset;
}
.bot-form:last-child {
  margin-bottom: 10px !important;
}
.bot-form:last-child {
  margin-bottom: 10px;
}
.bot-form input {
  width: auto;
  background: white;
  border: 1px solid #d3d3d382;
  font-size: 14px;
  display: block;
}
.bot-form label {
}
.bot-form-btn {
  margin-top: 10px;
}
.chat-bot-colse-img {
  width: 100%;
  height: 100%;
}
.chat-bot-colse-img img {
  width: 100%;
  height: 100%;
}
.curosol-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 5px;
}
.curosol-item {
  cursor: pointer;
}
.curosol-item img {
  width: 90px;
  border-radius: 3px;
  height: 70px;
  object-fit: cover;
}
.curosol-item p {
  font-size: 13px;
  line-height: 14px;
}
.bot-demo-wrapper {
  position: absolute;
  background: white;
  width: 100%;
  inset: 0;
  height: 100%;
  background-image: url('https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGlnaHRpbmd8ZW58MHx8MHx8&w=1000&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.samsung-bot-demo-wrapper {
  background-image: url('https://img.lovepik.com/background/20211021/large/lovepik-paper-texture-background-image_400542030.jpg');
}
/* Swiper bullet */

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
  text-transform: none !important;
  font-weight: 800 !important;
  font-family: var(--font-montserrat);
  color: var(--brand-color) !important;
}
.chatbot-swiper-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.booking-summery-wrap {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  border-top-left-radius: unset;
  grid-gap: 0px;
}
.booking-summery-wrap li {
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
  margin: unset !important;
}
.booking-end-msg {
  border-top-left-radius: unset;
  margin-top: 5px;
}
.sum-item-room-type-badge {
  background: #3f51b5;
  padding: 1px 11px;
  border-radius: 15px;
  color: white;
  font-weight: 500;
  font-size: 13px;
}
.sum-item-badge {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker {
  border: 1px solid #aeaeae59 !important;
}
/* .dashboard-app-table-wrap {
  padding: 10px;
  background: var(--bg-light);
  margin-top: 10px;
  border-radius: 3px;
} */

.highlight-name {
  font-weight: 600;
  display: contents;
  color: #009688;
}

.payment-question-text {
  margin-top: 15px;
}
.mobile-version-kpi-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 10px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 15px;
}
.mobile-version-kpi-wrapper .featured-status-item {
  width: 100%;
  min-height: unset;
  height: unset;
  border-radius: 50%;
  padding: 10px;
  border: unset;
  position: relative;
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 4px 0px black;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-content {
  grid-gap: 5px;
  flex-direction: column-reverse;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-content h3 {
  font-size: 13px;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-content h1 {
  font-size: 31px;
}
.temp {
  width: 500px;
  height: 100%;
}
.mobile-kpi-item-one {
  /* background: #fc9d2b !important; */
}
.mobile-kpi-item-two {
  /* background: #1a58ff !important; */
}
.mobile-kpi-item-three {
  /* background: #19cbd8 !important; */
}

/* for the mobile kpi */
.featured-status-item.mobile-kpi-item {
  background: #fff !important;
  border: 2px solid green !important;
}
.mobile-kpi-content h1,
.mobile-kpi-content h3 {
  color: green !important;
}
/* 
.featured-status-item.mobile-kpi-item-two {
  background: #fff !important;
  border: 2px solid #004AAD !important;
}

.featured-status-item.mobile-kpi-item-three {
  background: #fff !important;
  border: 2px solid #0566FF !important;
} */

.mobile-version-kpi-wrapper .featured-status-item .featured-status-thumb {
  display: unset;
  width: unset;
  height: unset;
  box-shadow: unset;
  border-radius: unset;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-thumb img {
  display: none;
}
.mobile-version-kpi-wrapper .featured-status-item .featured-status-thumb svg {
  color: #ffffff24;
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 30px;
}

/* new dashboard design */
.dashboard-new-design-wrapper {
  margin: 20px 0px;
  margin-top: unset;
}
.dashboard-new-design-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-header-left {
}
.dashboard-header-left h3 {
  color: var(--blue-color);
  font-size: 25px;
}
.dashboard-header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* dashboard new kpi */
.dashboard-kpi-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  margin: 20px 0px;
}
.dashboard-kpi-item {
  padding: 20px;
  border-radius: 4px;
  background: var(--bg-light);
  box-shadow: 0px 0px 5px 0px var(--shadowColor);
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  width: 100%;
  border-bottom: 4px solid transparent;
  min-height: 175px;
  cursor: pointer;
}
.dashboard-kpi-item.react-tabs__tab--selected {
  border-bottom: 4px solid #3b8ad8;
  border-radius: 6px;
  background: var(--bg-light);
}

.kpi-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 40px;
}
.kpi-item-header h4 {
  color: var(--h-color);
  font-size: 16px;
  line-height: 23px;
}
.kpi-item-header p {
  text-align: end;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  color: lightslategray;
}
.kpi-item-body h2 {
  color: var(--h-color);
}
.kpi-item-footer {
  height: 6px;
  width: 100%;
  background: #e9ecef;
  border-radius: 20px;
  position: relative;
}
.dashboard-kpi-item .kpi-item-footer::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20%;
  height: 100%;
  background: #3b8ad8;
  border-radius: 20px;
}
.da-kpi-one .kpi-item-footer::before {
  width: 20%;
  /* background: #11b85c; */
}
.da-kpi-two .kpi-item-footer::before {
  width: 40%;
  /* background: #aa67cc; */
}
.da-kpi-three .kpi-item-footer::before {
  width: 10%;
  /* background: #ffa044; */
}
.da-kpi-four .kpi-item-footer::before {
  width: 70%;
  /* background: #34b5e5; */
}
.download-btn-wrap {
  background: var(--blue-color);
  height: 45px;
  width: 55px;
  padding: 5px;
  border-radius: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow);
}
.dwn-btn {
  color: white;
  font-size: 25px;
}
.display-none {
  display: none;
}
.status-badge,
.unique-id {
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
}
.unique-id {
  background: #0096880d;
  color: #009688;
}
.status-badge.off-badge {
  color: white;
  background: #e91e63;
}
.status-badge.on-badge {
  background: #009688;
  color: white;
}
.reset-filter-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 6px;
  border: 1px solid #d3d3d363;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}
.reset-filter-btn span {
  width: max-content;
}
.notification-sidebar-details-wrapper {
}
.notification-sidebar-details-wrapper li {
  /* display: flex;
  justify-content: flex-start;
  grid-gap: 50px;
  align-items: center; */
}
.notification-sidebar-details-wrapper li span:first-child {
  margin-right: 70px;
}
.kpi-tab-header-wrap {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.kpi-tab-header-wrap li {
  font-weight: 500;
  color: #808080cc;
}
.interaction-count-badge {
  background: #5956e912;
  color: #929292 !important;
  font-weight: 700;
  padding: 3px 8px;
  font-size: 12px !important;
}
.kpi-tab-header-wrap li.react-tabs__tab.react-tabs__tab--selected {
  border: 1px solid #5956e924 !important;
  color: var(--blue-color);
  font-weight: 600;
}

.kpi-tab-header-wrap
  li.react-tabs__tab.react-tabs__tab--selected
  .interaction-count-badge {
  color: var(--blue-color) !important;
}

.noti-date-filter {
  box-shadow: unset;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 0px 10px;
}
.noti-date-filter input {
  border: unset !important;
}
.sec-badge {
  font-size: 16px;
  margin-left: 3px;
}

.new-area-chart {
  margin-top: 50px;
}

/* chatlog card */
.chatlog-profile-card {
  background: var(--bg-light);
  margin: 10px;
  position: relative;
  min-width: 290px;
}
.chatlog-card-thumb {
  width: 100%;
  height: 80px;
  background: var(--blue-color);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.chatlog-card-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  display: none;
}
.chatlog-card-wrapper {
  padding: 15px;
  padding-top: unset;
}
.chatlog-card-content {
  background: var(--bg-light);
  max-width: 90%;
  position: relative;
  margin: auto;
  margin-top: -15px;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  display: none;
}
.chatlog-card-content.active {
  display: block;
}
.chatlog-card-content h2,
.chatlog-card-content h3,
.chatlog-card-content h4 {
  font-size: 17px;
  line-height: 25px;
}
.chatlog-card-content h3 {
  font-weight: 400;
  font-size: 15px;
  color: gray;
}
.chatlog-card-content h4 {
  font-size: 13px;
  color: darkgray;
}
.chatlog-card-info {
}
.chatlog-info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  border-top: 2px solid #d3d3d324;
  padding-top: 10px;
}
.chatlog-info-title p {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.chatlog-info-title svg {
  font-size: 15px;
}
.info-group {
}
.info-group label {
  font-size: 12px;
  color: #8080808a;
}
.info-group p {
  font-weight: 500;
  font-size: 15px;
}
.chatlog-card-media {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7px;
}
.chatlog-card-media img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
}
.chatlog-media-wrapper {
  cursor: pointer;
}
.card-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: -65px;
  border: 3px solid white;
}
.card-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.tab-content-wrapper {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
}
.tab-dynamic-content {
  width: 100%;
  background: white;
  padding: 10px;
}
.w-100 {
  width: 100%;
}
.react-tabs__tab-panel {
  width: 100%;
}

/* new tab */
.kpi-dynamic-title {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #5956e924 !important;
  color: var(--blue-color);
  font-weight: 600;
  margin-bottom: 10px;
}
.kpi-dynamic-title.react-tabs__tab--selected .interaction-count-badge {
  color: var(--blue-color) !important;
}
.report-label-value {
}
.specialist-suggestion {
  font-size: 14px;
}
.specialist-suggestion p {
  font-size: 14px;
  color: #000000e3 !important;
}

.noti-table {
  display: flex;
  justify-content: flex-start;
  grid-gap: 65px;
}
.noti-table-header {
}
.noti-table-body {
}
.noti-table-header li {
}
.noti-table-body li {
}
.analytics-tab-icon-wrap {
}
.analytics-tab-icon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.analytics-tab-icon.react-tabs__tab--selected {
  background: #add8e661;
}
.mini-bot-footer {
}
.mini-bot-footer input {
  border-radius: 30px;
}
.mini-bot-footer button {
  border-radius: 30px;
  width: 62px;
  height: 33px;
}
.mini-bot-footer svg {
}
.ref-li {
}
.bot-message-wrapper {
  height: 400px !important;
}
.lang-parent-wrap {
}
.lang-child-wrap {
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 30px;
}
.sales-bot-wrapper {
  background-image: url('https://static.vecteezy.com/system/resources/previews/004/243/021/original/abstract-template-background-white-and-bright-blue-squares-overlapping-with-halftone-and-texture-free-vector.jpg');
}

/* bulk message */
.init-bulk-message {
  border-radius: 3px;
  width: 100%;
  height: 70vh;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}
.init-bulk-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  width: 100%;
  height: 100%;
  font-family: var(--font-poppins);
  text-align: center;
  padding: 20px;
  /* border: 2px solid #d3d3d327;
  border-radius: 4px; */
}
.init-bulk-wrap h2 {
  font-size: 20px;
  color: gray;
  font-weight: 600;
}
.init-bulk-wrap p {
  font-size: 16px;
  color: #808080b3;
}
.init-button {
  background: var(--brand-color);
  color: var(--white-color);
  padding: 7px 10px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  font-family: var(--font-poppins);
  font-size: 13px;
  font-weight: 500;
}
.init-button svg {
  color: white;
}
.sample-btn {
}
.bulk-msg-create-user-form {
  width: 520px;
}
.number-import-item svg {
  color: gray;
}
.number-import-item span {
  color: gray;
}

/* bulk message */
.bulk-message-container {
  margin: 10px;
  background: white;
  padding: 20px;
}
.bulk-message-wrapper {
  display: grid;
  grid-template-columns: 4fr 9fr;
  grid-gap: 30px;
}
.bulk-message-wrapper.full-width {
  grid-template-columns: 1fr;
}
.bulk-table-wrap {
  width: 100%;
}
.bulk-field-wrap {
  width: 100%;
}
.bulk-selected-field {
}
.bulk-selected-field label {
}

.bulk-selected-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 7px;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 3px;
  border: 2px solid #d3d3d34a;
  /* height: 80px; */
  overflow-y: auto;
  min-height: 20px;
}

/* bulk selected box scrollbar width */
.bulk-selected-box::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* bulk selected box scrollbar Track */
.bulk-selected-box::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* bulk selected box scrollbar Handle */
.bulk-selected-box::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 5px;
  overflow-y: hidden;
}

/* bulk selected box scrollbar Handle on hover */
.bulk-selected-box::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.bulk-selected-box li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2px;
  background: #5d86f617;
  border-radius: 30px;
  padding: 0px 6px;
  /* display: inline-block; */
  color: var(--brand-color);
  font-weight: 600;
  font-size: 12px;
}
.bulk-selected-box li svg {
  cursor: pointer;
  font-size: 19px;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 7px;
}
.bulk-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #d3d3d31f;
  padding-bottom: 10px;
  margin-top: -10px;
}
.bulk-message-header h3 {
  font-size: 18px;
  color: var(--gray-color);
  font-weight: 600;
}
.c-btn {
  background: var(--blue-color);
  color: white;
  border-radius: var(--radius);
  box-shadow: var(--hover-shadow);
  text-align: center;
  padding: 10px 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  min-width: 120px;
  min-height: 40px;
  width: max-content;
}
.right-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bulk-media-thumb {
  border: 2px dotted lightgray;
  width: 100%;
  height: 100px;
  display: flex;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bulk-media-thumb img {
  width: 100%;
  height: 100%;
  border-radius: unset;
}
.bulk-update-profile {
  position: unset;
  height: auto;
  width: 100%;
  box-shadow: unset;
}
.bulk-profile {
  position: unset;
  width: 100%;
  border-radius: unset;
  margin: 0px auto;
  box-shadow: unset;
  grid-gap: 8px;
}
.bulk-group {
}
.bulk-group input,
.bulk-group textarea {
  border: 1px solid #d8d8d8;
  background: white;
}

.bulk-group input:focus,
.bulk-group textarea:focus {
  border: 1px solid var(--brand-color) !important;
}

.bulk-group textarea {
  height: 100px;
}
.bulk-group input::placeholder,
.bulk-group textarea::placeholder {
  font-size: 14px;
}
.bulk-table td,
.bulk-table th {
  border: unset;
  padding: 5px;
}
.table-wrap-bulk {
  height: 75vh;
  overflow-y: auto;
}

/* recent chat scrollbar width */
.table-wrap-bulk::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.table-wrap-bulk::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.table-wrap-bulk::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.table-wrap-bulk::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.list-of-bulk-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #fafafa;
  padding-bottom: 10px;
  margin-bottom: 10px;
  grid-gap: 15px;
  position: relative;
}
.list-of-bulk-title p {
  font-size: 14px;
  color: unset;
}
.list-of-bulk-title svg {
  color: #a4a4a4;
}
.list-of-bulk-title .bulk-clear-icon {
  position: absolute;
  top: 5px;
  right: 6px;
  font-size: 25px;
  z-index: 9999;
  background: white;
}
.bulk-search-input-wrap {
  position: relative;
}
.not-yet-selected {
  background: #e9565612;
  color: var(--brand-color);
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  margin-bottom: 5px;
}
.not-yet-selected p {
  font-size: 13px;
  font-weight: 500;
}
.form-check-input {
  width: 17px;
  height: 17px;
}
.bulk-media-preview {
  width: 100%;
  height: 300px;
  position: relative;
}
.bulk-media-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.media-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
  background: #ffffffb4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-remove-icon {
  font-size: 28px;
  color: firebrick;
}
.loading-wrap {
  background: #80808033;
  border-radius: 4px;
}
.bulk-import-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.bulk-import-title span {
  font-size: 17px;
  font-weight: 500;
  color: var(--blue-color);
}
.bulk-import-title svg {
  color: var(--blue-color);
}
.init-bulk-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-top: 10px;
}
.bulk-not-found-icon {
  font-size: 26px;
  color: var(--blue-color);
}
.init-exl-wrap {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3b8ad81a;
  /* box-shadow: 0px 0px 5px 0px #ff000026; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.exp-btn {
  background: var(--blue-color);
}
.sample-btn {
  background: #808080b3;
  border: unset;
}
.number-btn {
  position: absolute;
  top: 7px;
  right: 7px;
  background: var(--blue-color);
  color: white;
  border-radius: 3px;
  padding: 6px 14px;
}
.add-number-wrap {
  position: relative;
}
.search-number-input {
  height: 35px;
}
.c-btn:disabled {
  /* Add your styles for the disabled button here */
  background-color: gray;
  color: white;
  cursor: not-allowed;
}
.c-btn-remove {
  background: #ffffff;
  color: rgba(178, 34, 34, 0.815);
  border: 1px solid #b2222236;
}

/* faq bot style */
.faq-recipient-avatar {
  width: 45px;
  height: 45px;
}
.faq-recipient-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  object-fit: contain;
  background: unset;
  box-shadow: var(--brand-shadow);
  border: unset;
}

/* rich content */
.article-body {
  width: 100%;
}
.article-body p {
  font-size: 12px;
  line-height: 17px;
  color: #000 !important;
}
.article-body img {
  width: 100%;
}
.article-body p strong {
}
.article-body ul {
}
.article-body ul li {
  margin: unset;
  display: unset;
}
.article-body ul li a {
}
.article-body ul li em {
}
.article-body ul li span {
}
.article-body ul li u {
}
.article-body ul li p.wysiwyg-indent4 {
}
.article-body ul li span.wysiwyg-color-black {
}
.article-body ul li a.article-list-link {
}
.article-body p.wysiwyg-indent4 {
}
.article-body p.wysiwyg-indent6 {
}
.article-body p.wysiwyg-indent7 {
}
.article-body ul li a.article-list-link {
}
.faq-bubble-agent {
  font-family: var(--font-montserrat);
  font-size: 14px;
}
.faq-bubble-visitor {
  margin: 10px 0px !important;
  margin-top: unset !important;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 13px;
  padding: 10px 12px;
}
.faq-bubble-wrap p {
  font-size: 13px;
  line-height: 20px;
  color: #000;
}
.faq-bubble-wrap h2 {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
}
.faq-bubble-wrap ul {
  margin: 10px 15px;
}
.faq-bubble-wrap ul li {
  display: block;
  margin: unset;
  line-height: 21px;
  color: #000;
}
.faq-bubble-wrap br {
  display: block;
  margin-bottom: 2px;
  font-size: 2px;
  line-height: 2px;
}
.faq-message-body ul li {
  margin-bottom: 3px;
  font-size: 13px;
  line-height: 20px;
}
.message-bubble-filled {
  background: var(--bg) !important;
  color: var(--paragraph-color) !important;
  border-top-left-radius: 0px !important;
  border: 1px solid var(--bg) !important;
}
.faq-select-wrap {
}
.faq-select-wrap input {
  color: black !important;
}
/* .faq-select-wrap .css-tj5bde-Svg {
  width: 10px;
  color: white;
  fill: white;
}
.faq-select-wrap .css-14el2xx-placeholder{
  color: white;
} */
.language-select-box {
  font-size: 12px;
  background: unset;
  border: 1px solid #ffffff75 !important;
  color: white;
  padding: 6px 4px;
}
.language-select-box option {
  background: #5c7287;
  color: white;
  border-bottom: 1px solid white;
}

.faq-curosol-agent {
}
.faq-swiper-container {
  margin-bottom: unset !important;
}
.date-bubble-item {
  margin-bottom: 22px !important;
}
.samsung-button-wrap {
  grid-gap: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.samsung-button-wrap button {
  width: 100%;
  border-radius: 3px;
  border-bottom: unset;
  text-align: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
}
.samsung-button-wrap button:last-child {
  border-bottom: 1px solid;
}
