/* This is for only desktop version */
@media only screen and (min-width: 768px) {
  .desktop-hide {
    display: none;
  }
  .mobile-sidebar-cross {
    display: none;
  }
  .mobile-version-kpi-wrapper {
    display: none;
  }
}

/* This is for mobile or small devices version */
@media only screen and (max-width: 768px) {
  .mobile-hide {
    display: none;
  }
  .breadcrumbs {
    display: none;
  }
  header {
    padding: 0px 10px;
  }
  .container {
    padding: 0px;
  }

  aside.sidebar {
    width: 0px;
  }
  aside.sidebar.active {
    width: 100% !important;
  }
  .featured-status-wrapper,
  .chart-wrapper {
    flex-direction: column;
  }
  .chart-wrapper {
    margin-top: 15px;
    grid-gap: 10px;
    display: none;
  }
  .date-filter {
    width: 100%;
  }
  .p-20 {
    padding: 0px;
  }
  .analytics-wrapper {
    grid-template-columns: 1fr;
  }
  .table-container {
    margin: 0px;
  }
  .table-filter-container {
    flex-direction: column;
    display: none;
  }
  .table-filter {
    flex-direction: column;
  }
  .create-user-form {
    max-width: 95%;
  }
  .pagination-wrapper {
    margin-top: unset;
  }
  .integrate-container {
    padding: 0;
  }
  .integrate-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .settings-container {
  }
  .settings-wrapper form {
    padding: 15px;
    display: flex;
    flex-direction: column-reverse;
  }
  main {
    width: 100%;
    margin: 0px;
  }
  main.active {
    width: 100%;
    margin-left: 0px;
  }
  .body-wrapper {
    padding: 10px;
    width: 100%;
  }
  .search-box input {
    height: 38px;
    width: 230px;
  }
  .breadcrumbs-and-filter {
    justify-content: flex-end;
  }
  .featured-status-wrapper {
    margin-top: 10px;
    grid-gap: 10px;
    overflow-x: scroll;
  }
  .search-box {
    display: none;
  }
  .chart-svg-wrapper {
    height: 170px;
    overflow: hidden;
  }
  aside.sidebar.mobile-active {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .not-found-content h1 {
    font-size: 120px;
    line-height: 160px;
  }
  .not-found-wrapper {
    max-width: 95%;
  }
  /* admin */
  .chat-body-sidebar {
    /* display: none; */
    min-width: unset;
    min-height: unset;
    max-height: unset;
    height: 100%;
    max-width: 100%;
    padding: 5px;
  }
  .admin-header {
    /* position: fixed; */
    width: 100%;
    flex-direction: row;
    min-width: unset;
    max-width: unset;
    height: unset;
    min-height: unset;
    z-index: 1;
    flex-direction: column;
    padding: unset;
  }
  .admin-page-wrapper {
    height: unset;
    flex-direction: column;
  }
  .admin-header ul:last-child {
    display: none;
  }
  .admin-header ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    grid-gap: 0px;
    flex-direction: revert;
    width: 100%;
    position: fixed;
    align-items: center;
    bottom: 0px;
    background: white;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px lightgray;
    height: 60px;
  }
  .recent-chat {
    height: unset;
    max-height: unset;
  }
  .chatbox-message-body {
    display: none;
  }
  .recent-chat-wrap {
    padding-bottom: 30px;
  }
  .chat-title-big h2 {
    font-size: 23px;
    font-weight: 600;
  }
  .admin-header ul li svg {
    font-size: 20px;
    color: var(--gray);
  }
  .admin-header ul li img {
    width: 21px;
  }
  .chat-body-wrapper {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  .chatbox-message-body.active {
    display: block;
    position: absolute;
    inset: 0;
    background: white;
  }
  .chatbox-header.active {
    position: sticky;
    width: 100%;
    z-index: 999999999;
    background: white;
    box-shadow: 0px 0px 5px 0px lightgray;
    top: 60px;
  }
  .chatbot-creator {
    display: none;
  }
  .chatbox-header-right-options {
    grid-gap: 10px;
  }
  .chatbox-header-icon svg {
    font-size: 18px;
  }
  .chatbox-creator p {
    font-size: 10px;
    color: gray;
  }
  .chatbot-creator img {
    width: 15px;
    border-radius: 2px;
  }
  .mobile-header-burger-menu {
    cursor: pointer;
  }
  .mobile-header-burger-menu img {
    width: 30px;
    cursor: pointer;
  }
  .chatbox-footer {
    position: fixed;
    bottom: 0px;
    background: white;
    width: 100%;
  }
  .chat-conversation-wrapper {
    padding: 0px 5px;
  }
  .message-body li {
    grid-gap: 5px;
    margin-bottom: 20px !important;
  }
  .chatbox-conversation {
    height: 100%;
    max-height: 100%;
  }
  .chatbox {
    position: fixed;
    inset: 0;
  }
  ul.message-body {
    padding: 80px 0px;
    padding-bottom: 0px;
    padding-top: 60px;
  }
  .chatbot-footer {
    padding: 0px 15px;
  }
  .analytics-guide {
    height: auto;
    padding: 30px;
    width: 100%;
  }
  header.active {
    width: 100%;
  }
  .chatbox-close-icon {
    font-size: 25px !important;
  }
  .message-day-slot-item {
    padding: 1px 10px;
  }
  .chatbot-widget-header-right {
    position: static;
  }
  .chatbot-widget {
    transition: unset;
  }
  .search-model.active {
    right: 20px;
    /* left: 0; */
    width: 90%;
    margin: auto;
  }
  .search-model input {
    width: 100%;
  }
  .register-wrapper form {
    width: 92%;
    padding: 30px 15px;
  }
  /* user profile */
  .user-profile-section {
    margin: 5px;
  }
  .user-profile-body form {
    max-width: 100%;
    padding: 15px;
  }
  .user-profile-body {
    margin-top: 0px;
    padding: 12px;
  }
  .user-profile-header-thumb {
    width: 100px;
    height: 100px;
  }
  .user-profile-header-wrapper {
    padding: 10px;
    background: #b4e6ff;
  }
  .user-join-date p {
    display: none;
  }
  .chatbot-widget-wrapper {
    position: unset;
    width: 100%;
    height: 100%;
  }
  .chatbot-widget.active {
    height: 100%;
    width: 100%;
    border-radius: unset;
    position: fixed;
    inset: 0;
    z-index: 9999;
  }
  .chat-conversation-wrapper {
    height: 100%;
    min-height: unset;
    max-height: unset;
    padding-bottom: 100px;
  }
  .chatbot-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: white;
    padding: 10px;
    height: 65px;
    z-index: 99;
  }
  .chatbot-footer form {
    width: 96%;
    margin: auto;
  }
  .chatbot-recipient-avatar {
    position: relative;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 5px 0px lightgray;
    border-radius: 50%;
  }
  .chatbot-recipient-avatar img {
    width: 100%;
    height: 100%;
  }
  .chatbot-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #fcfcfc !important;
    padding: 10px;
    border-radius: unset;
    /* box-shadow: 0px 35px 7px 0px lightgray; */
    overflow: hidden;
    border-bottom: 2px solid #d3d3d31f;
  }
  .chatbot-widget-recipient-details h3 {
    font-weight: 500;
    line-height: 13px;
    font-size: 14px;
    color: gray;
  }
  .chatbot-mini-preview-wrap {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-top-left-radius: 20px;
    padding: 8px 30px;
  }
  aside.sidebar ul li a {
    justify-content: flex-start;
  }
  .featured-status-thumb {
    display: none;
  }
  .featured-status-item {
    padding: 20px;
    width: 200px;
    height: 150px;
  }
  .blank {
    display: none;
  }
  .date-item input {
    font-size: 13px;
  }
  .dashboard-kpi-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 10px;
  }
  .dashboard-new-design-header {
    display: none;
  }
  .chatlog-profile-card {
    display: none;
  }
  .chatbot-widget-recipient-details p {
    display: none;
  }
  .kpi-item-header {
    grid-gap: 5px;
  }
  .chatbox-conversation-form {
    width: 100%;
  }
  .details-sidebar {
    right: -100px;
    width: 100%;
    padding: 15px;
  }
  .refresh-icon svg {
    fill: #607d8b !important;
    font-size: 25px !important;
    border-radius: unset;
    width: unset;
    height: unset;
  }
  .bot-message-wrapper {
    height: 100% !important;
  }
  .bulk-message-wrapper {
    grid-template-columns: 1fr;
  }
  .bulk-media-thumb {
    width: 100%;
  }
  .bulk-message-container {
    margin: unset;
    background: white;
    padding: 10px;
  }
  .bulk-message-header{
    grid-gap: 10px;
    margin-top: 0px;
  }
  .bulk-message-header h3 {
    font-size: 16px;
  }
  .table-wrap-bulk {
    height: 300px;
    overflow-y: auto;
  }
  .language-select-box{
    color: gray;
  }
  .chatbot-widget-options svg, .chatbot-widget-minimize svg, .delete-chat-icon svg {
    fill: gray;
    color: gray;
  }
  .header-profile p {
    font-size: 13px;
    line-height: 18px;
}
.chatbot-message-body {
  padding: 5px !important;
}
}
